<script setup lang="ts">
import { computed } from "vue";
import UIProductCard from "@/components/molecules/Card/ProductCard";
import type { BoxLayout, DisplayMode } from "@shopware-pwa/composables-next";
import { getProductName, getProductRoute } from "@shopware-pwa/helpers-next";
import { ApiClientError } from "@shopware/api-client";
import type { Media } from "@shopware-pwa/types";
import type { ProductCardSize } from "@/components/molecules/Card/ProductCard/ProductCard.interface";
import { customFieldKeyResolver } from "@/constants/customFieldKeyResolver";
import { useStoreApi } from "@/composables/useStoreApi";
import { NotifyMeFormState, type ProductWithCustomFields } from "@/types";
import {
  CrehlerBackInStockExtensionAdapter,
  type CrehlerBackInStockExtensionResponse,
} from "@/adapters";

import { useProductLabelsInCategory } from "@/stores";
import { productAlternativeButtonActions } from "@/constants/productAlternativeButtonActions";

const { productLabels } = toRefs(useProductLabelsInCategory());
const { pushSuccess, pushError } = useNotifications();
const { t } = useI18n();
const { getErrorsCodes } = useCartNotification();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { getFormattedPrice } = usePrice();
const { wishlistItemsCount } = toRefs(useWishlistStore());

const { getMediaByMediaIds } = useStoreApi();

const crehlerBackInStockExtensionAdapter =
  new CrehlerBackInStockExtensionAdapter();

const props = withDefaults(
  defineProps<{
    product: ProductWithCustomFields;
    layoutType?: BoxLayout;
    displayMode?: DisplayMode;
    size?: ProductCardSize;
    alternativeAction?: "close";
  }>(),
  {
    layoutType: "standard",
    displayMode: "standard",
    size: "regular",
  },
);
const { product } = toRefs(props);
const { addToCart } = useAddToCartWithSideEffects(product);
const { addToWishlist, removeFromWishlist, isInWishlist } = useProductWishlist(
  product.value.id,
);

const { unitPrice, referencePrice, price } = useProductPrice(product);

const { user } = useUser();

const toggleWishlistProduct = async () => {
  if (!isInWishlist.value) {
    try {
      await addToWishlist();
      wishlistItemsCount.value++;
      return pushSuccess(
        t(`product.messages.addedToWishlist`, {
          p: props.product?.translated?.name,
        }),
      );
    } catch (error) {
      if (error instanceof ApiClientError) {
        const reason = error.details.errors?.[0]?.detail
          ? `Reason: ${error.details.errors?.[0]?.detail}`
          : "";
        return pushError(
          `${props.product?.translated?.name} cannot be added to wishlist.\n${reason}`,
          {
            timeout: 5000,
          },
        );
      }
    }
  }
  removeFromWishlist();
  wishlistItemsCount.value--;
};

const { locale } = useI18n();
const futureReadableReleaseDate = computed(() => {
  if (!product.value?.releaseDate) return;
  const releaseDate = new Date(product.value?.releaseDate ?? "");

  const defaultOptions: Intl.DateTimeFormatOptions = {
    month: "long",
    year: "numeric",
  };
  const withoutDayFormat = new Intl.DateTimeFormat(
    locale.value,
    defaultOptions,
  );
  const withDayFormat = new Intl.DateTimeFormat(locale.value, {
    ...defaultOptions,
    day: "numeric",
  });

  const readableReleaseDateWithDay = withDayFormat.format(releaseDate);
  const readableReleaseDateWithoutDay = withoutDayFormat.format(releaseDate);

  return {
    withDay: readableReleaseDateWithDay,
    withoutDay: readableReleaseDateWithoutDay,
  };
});

const addToCartProxy = async () => {
  await addToCart();

  getErrorsCodes()?.forEach((element) => {
    pushError(t(`errors.${element.messageKey}`, { ...element }));
  });
  pushSuccess(
    t(`cart.messages.addedToCart`, { p: props.product?.translated?.name }),
  );
};

const computedReadablePricePerUnit = computed(() => {
  if (
    !product.value.calculatedPrice?.referencePrice?.price ||
    !product.value.unit?.translated?.shortCode
  ) {
    return;
  }

  return `(${getFormattedPrice(
    referencePrice.value?.price,
  )}/${product.value.unit?.translated?.shortCode})`;
});

const secondProductMedia = computed(() => {
  const disableShowingOtherMediaOnHover =
    product.value?.customFields?.[
      customFieldKeyResolver["PRODUCT_DISABLE_SHOWING_OTHER_MEDIA_ON_HOVER"]
    ];

  if (disableShowingOtherMediaOnHover) return undefined;
  const mediasWithoutMediaCover = product.value.media
    ?.filter((media) => media.mediaId !== product.value.cover?.mediaId)
    .sort((a, b) => {
      if (a.position && b.position) {
        return a.position - b.position;
      }

      return 0;
    });
  if (mediasWithoutMediaCover && mediasWithoutMediaCover?.length > 0) {
    return mediasWithoutMediaCover[0];
  }
  return undefined;
});

const isProductNotBuyAble = computed(() => {
  if (
    product.value?.customFields?.[
      customFieldKeyResolver["PRODUCT_NOT_BUYABLE_MIGRATION"]
    ]
  )
    return true;
  return false;
});

const getMediaOnHover = async () => {
  const disableShowingOtherMediaOnHover =
    product.value?.customFields?.[
      customFieldKeyResolver["PRODUCT_DISABLE_SHOWING_OTHER_MEDIA_ON_HOVER"]
    ];

  if (disableShowingOtherMediaOnHover) return;

  const mediaIdOnHover =
    props.product?.customFields[
      customFieldKeyResolver["PRODUCT_MEDIA_ON_HOVER"]
    ] ||
    props.product?.customFields[
      customFieldKeyResolver["PRODUCT_MEDIA_ON_HOVER_MIGRATION"]
    ];
  if (!mediaIdOnHover) return;

  const data = await getMediaByMediaIds([mediaIdOnHover]);

  return Array.isArray(data) ? data?.[0] : undefined;
};

const mediaOnHover = await getMediaOnHover();

const alternativeButtonAction = computed(() => {
  const hasFutureReleaseDate = product.value?.releaseDate
    ? new Date(product.value.releaseDate) > new Date()
    : false;
  if (!product.value?.availableStock || product.value.availableStock <= 0) {
    if (hasFutureReleaseDate) {
      return productAlternativeButtonActions["NOTIFY_ME_FUTURE_RELEASE_DATE"];
    }
    return productAlternativeButtonActions["NOTIFY_ME_OUT_OF_STOCK"];
  }

  return;
});

const notifyMeFormState = ref<NotifyMeFormState>(NotifyMeFormState.DEFAULT);

const handleNotifyMeFormSubmit = async (formValues: { email: string }) => {
  const subscribeResult = await crehlerBackInStockExtensionAdapter.subscribe({
    email: formValues.email,
    productId: product.value.id,
    customerId: user?.value?.id,
  });

  const subscribeDataResult = subscribeResult?.data?.value as
    | CrehlerBackInStockExtensionResponse["subscribe"]
    | undefined;

  if (subscribeDataResult?.success) {
    notifyMeFormState.value = NotifyMeFormState.SUBSCRIBED;
  }
};

const handwrittenLabelCustomFieldsData = computed(() => {
  const productCustomFields = product.value?.customFields;
  if (!productCustomFields) return;
  if (
    !productCustomFields[
      customFieldKeyResolver["PRODUCT_HANDWRITTEN_LABEL_SHOULD_DISPLAY"]
    ]
  )
    return;

  return {
    position:
      productCustomFields[
        customFieldKeyResolver["PRODUCT_HANDWRITTEN_LABEL_POSITION_VALUE"]
      ],
    label:
      productCustomFields[
        customFieldKeyResolver["PRODUCT_HANDWRITTEN_LABEL_TEXT_VALUE"]
      ],
    hideOnCardHover:
      !product.value?.customFields?.[
        customFieldKeyResolver["PRODUCT_DISABLE_SHOWING_OTHER_MEDIA_ON_HOVER"]
      ],
    size: props.size,
  };
});
</script>

<template>
  <UIProductCard
    v-model:notify-me-form-state="notifyMeFormState"
    :size="size"
    :media-preview="product.cover?.media as Media"
    :second-media="secondProductMedia?.media as Media | undefined"
    :media-on-hover="mediaOnHover"
    :is-on-wishlist="isInWishlist"
    :title="getProductName({ product })"
    :actual-unit-price="getFormattedPrice(unitPrice)"
    :old-unit-price="getFormattedPrice(price?.listPrice?.price) || undefined"
    :price="computedReadablePricePerUnit"
    :formatted-product-route="
      isProductNotBuyAble ? '' : formatLink(getProductRoute(product))
    "
    :handwritten-label-data="handwrittenLabelCustomFieldsData"
    :is-not-buyable="isProductNotBuyAble"
    :alternative-action="alternativeAction"
    :label="productLabels?.[product?.id]?.[0]?.name"
    :alternative-primary-button-action="alternativeButtonAction"
    data-testid="product-box"
    @add-to-cart="addToCartProxy"
    @toggle-wishlist="toggleWishlistProduct"
    @notify-me-form-submit="handleNotifyMeFormSubmit"
    :future-readable-release-date="futureReadableReleaseDate"
  />
</template>
